<template>
  <v-container>
    <Breadcrumb :items="breadcrumb" />
    <h1 class="b-4" v-html="title"></h1>
    <p v-html="content"></p>
    <div>
      <v-expansion-panels class="faq-list" accordion>
        <v-expansion-panel
          class="rounded mt-3"
          v-for="item in faqList"
          :key="item.id"
        >
          <v-expansion-panel-header>
            <p>
              <strong v-html="item.title.rendered"></strong>
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.content.rendered"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-btn
      :title="$t('navbar.faq.backBtn')"
      class="back-button mt-6"
      large
      color="primary"
      depressed
      :to="{ name: 'FaqCategory' }"
    >
      {{ $t("navbar.faq.backBtn") }}
    </v-btn>
  </v-container>
</template>
<style scoped>
img.size-full {
  width: 100%;
}
.v-expansion-panel-header {
  padding: 16px 28px;
  font-size: 16px;
}
.v-expansion-panel-content__wrap {
  padding: 0 28px 16px;
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import page from "~/mixins/page";
import CMSService from "~/service/cmService";
import get from "lodash/get";
export default {
  name: "FaqList",
  data() {
    return {
      faqList: null
    };
  },
  components: { Breadcrumb },
  mixins: [page],
  computed: {
    title() {
      return get(this.page, "name");
    },
    content() {
      return get(this.page, "description");
    }
  },
  jsonld() {
    if (this.faqList) {
      const items = this.faqList.map(item => ({
        "@type": "Question",
        name: item.title.rendered,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.content.rendered
        }
      }));
      return {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        mainEntity: items
      };
    }
  },
  mounted() {
    // this.page = this.$route.params.page;
    this.fetchPageData(this.$route.params.categoryName);
    this.fetchFaqList(this.$route.params.categoryName);
  },
  methods: {
    async fetchFaqList(categoryName) {
      const res = await CMSService.getCustomPostByFilters("faq", [
        {
          filterName: "filter[categoria_faq]",
          filterValue: categoryName
        }
      ]);

      if (res && res.data && res.data.length) {
        this.faqList = res.data;
      }
    },
    async fetchPageData(categoryName) {
      const result = await CMSService.getCustomPostBySlug(
        "categoria_faq",
        categoryName
      );
      if (result && result.length) {
        this.page = result[0];
      }
    }
  },
  metaInfo() {
    let title = `${get(this, "page.name")} - Le domande più frequenti`;
    return {
      title: title,
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
